import React, { useEffect, useRef, useState } from "react";
import "../scss/BlogHeader.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Helmet } from "react-helmet";
import FK from "./FK.png"; 
import email from "./email_black.png"; 

export default function HomePageFeature1() {
  const data = useStaticQuery(graphql`
  query MyQuery100 {
    allContentfulHeroImage (filter:{id:{eq:"883e2d6d-ddc6-5719-94f6-a0e0f4840903"}}){
      edges {
        node {
          blogCategory
          blogTitle
          date
          readTime
          author {
            firstName
          }
          id
          socialMediaIcons {
            linkedInIcon {
              image {
                file {
                  url
                }
              }
            }
            facebookIcon {
              image {
                file {
                  url
                }
              }
            }
          }
          heroImage {
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
    text1: allContentfulTextBlock (filter:{id:{eq:"bc17542f-a740-5f8b-8a1b-bf71605eb53d"}}){
      edges {
        node {
          bodyText {
            raw
          }
          id
          title
        }
      }
    }
    text2: allContentfulTextBlock (filter:{id:{eq:"ba1ef55d-68ac-5ca8-a56f-8765ebfdf7fa"}}){
      edges {
        node {
          bodyText {
            raw
          }
          id
          title
        }
      }
    }
    text3: allContentfulTextBlock (filter:{id:{eq:"c36ba338-404b-529e-983d-1f3787ac1e41"}}){
      edges {
        node {
          bodyText {
            raw
          }
          id
          title
        }
      }
    }
    subtitle: allContentfulTextBlock (filter:{id:{eq:"946fd000-1d87-5c12-b998-2890fa52ee0a"}}){
      edges {
        node {
          bodyText {
            raw
          }
          id
          title
        }
      }
    }
    timelineimage1: allContentfulImageBlock (filter:{id:{eq:"47149b62-52e4-5f7c-a1db-639f4cf03dee"}}){
      edges {
        node {
          image {
            altText
            image {
              file {
                url
              }
            }
          }
          id
        }
      }
    }
    timelineimage2: allContentfulImageBlock (filter:{id:{eq:"bcccfea0-e63c-50e6-ad61-6762fdf31dfc"}}){
      edges {
        node {
          image {
            altText
            image {
              file {
                url
              }
            }
          }
          id
        }
      }
    }
    timelineimage3: allContentfulImageBlock (filter:{id:{eq:"482383a2-1cb8-5d6a-9100-d40a7c9a3287"}}){
      edges {
        node {
          image {
            altText
            image {
              file {
                url
              }
            }
          }
          id
        }
      }
    }
    timelineimage4: allContentfulImageBlock (filter:{id:{eq:"9d877fc1-acd3-5cfa-8f69-823a7a0292ca"}}){
      edges {
        node {
          image {
            altText
            image {
              file {
                url
              }
            }
          }
          id
        }
      }
    }
    timelineimage5: allContentfulImageBlock (filter:{id:{eq:"b623a3b0-2f3d-5bc0-be39-99a24a0c5644"}}){
      edges {
        node {
          image {
            altText
            image {
              file {
                url
              }
            }
          }
          id
        }
      }
    }
    timelineimage6: allContentfulImageBlock (filter:{id:{eq:"5b19515e-f73e-59e1-be65-0d24ddadf413"}}){
      edges {
        node {
          image {
            altText
            image {
              file {
                url
              }
            }
          }
          id
        }
      }
    }
    timelinetext1: allContentfulTextBlock (filter:{id:{eq:"c836869f-578d-5c18-a33c-75be94358d50"}}){
      edges {
        node {
          id
          title
          year
          bodyText {
            raw
          }
        }
      }
    }
    timelinetext2: allContentfulTextBlock (filter:{id:{eq:"c8d710a0-99e7-5b93-8854-0ebd0bc61659"}}){
      edges {
        node {
          id
          title
          year
          bodyText {
            raw
          }
        }
      }
    }
    timelinetext3: allContentfulTextBlock (filter:{id:{eq:"9be960e6-d1a7-50d4-b833-826025509b10"}}){
      edges {
        node {
          id
          title
          year
          bodyText {
            raw
          }
        }
      }
    }
    timelinetext4: allContentfulTextBlock (filter:{id:{eq:"07a70a1a-b97e-5353-85bb-09e9ab9b926e"}}){
      edges {
        node {
          id
          title
          year
          bodyText {
            raw
          }
        }
      }
    }
    timelinetext5: allContentfulTextBlock (filter:{id:{eq:"f4dd3adb-5a56-58f1-949e-1c120176b071"}}){
      edges {
        node {
          id
          title
          year
          bodyText {
            raw
          }
        }
      }
    }
    timelinetext6: allContentfulTextBlock (filter:{id:{eq:"f641cedf-6fd5-507a-9fce-463db529fcb3"}}){
      edges {
        node {
          id
          title
          year
          bodyText {
            raw
          }
        }
      }
    }
  }
  `);

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    Aos.init({ duration: 2000 });

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const breakpoint = 2000; // Adjust this value to determine when the icons should disappear

      if (scrollPosition > breakpoint) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const options = {
    renderText: (text) => {
      // Split the text based on the word you want (e.g., "breakpoint-word")
      const breakpointWord = "calledp";
      const splitText = text.split(breakpointWord);
  
      // Create an array of React components
      const reactComponents = splitText.flatMap((text, index) => [
        // Add a <br> tag after the word in all elements except the last one
        <React.Fragment key={`text-${index}`}>{text}</React.Fragment>,
        index !== splitText.length - 1 && <br key={`br-${index}`} />,
      ]);
  
      return <React.Fragment>{reactComponents}</React.Fragment>;
    },
  };
  

  const [isSocialMediaBlogVisible, setIsSocialMediaBlogVisible] = useState(true);

  useEffect(() => {
    Aos.init({ duration: 2000 });

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 6500; // Adjust this value to determine when to hide socialMediaBlog

      if (scrollPosition > scrollThreshold) {
        setIsSocialMediaBlogVisible(false);
      } else {
        setIsSocialMediaBlogVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const [activeSection, setActiveSection] = useState("");

  const sectionIds = [
    "1997",
    "2004",
    "2017",
    "2018",
    "2022",
    "2023",
  ];

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = sectionIds.map((sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        return {
          id: sectionId,
          offsetTop: sectionElement.offsetTop,
          offsetBottom: sectionElement.offsetTop + sectionElement.offsetHeight,
        };
      });
  
      const currentScrollPosition = window.scrollY + window.innerHeight / 2;
  
      for (const { id, offsetTop, offsetBottom } of sectionOffsets) {
        if (currentScrollPosition >= offsetTop && currentScrollPosition <= offsetBottom) {
          setActiveSection(id);
          break;
        }
      }
    };
  
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  

  



  const [isContentsVisible, setIsContentsVisible] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  
    const showThreshold = 1100; // Adjust this value to determine when to show the contents
    const hideThreshold = 6500; // Adjust this value to determine when to hide the contents
    let isScrollingDown = false;
  
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
  
      if (scrollPosition > showThreshold && !isScrollingDown) {
        setIsContentsVisible(true);
        isScrollingDown = true;
      } else if (scrollPosition <= showThreshold && isScrollingDown) {
        setIsContentsVisible(false);
        isScrollingDown = false;
      }
  
      // Add logic to hide content near the bottom
      if (scrollPosition > hideThreshold) {
        setIsContentsVisible(false);
      }
    };
  
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  
  const text1Node = data.text1.edges[0].node;
  const text2Node = data.text2.edges[0].node;
  const text3Node = data.text3.edges[0].node;
  const subtitle1 = data.subtitle.edges[0].node;
  const timelineimage1 = data.timelineimage1.edges[0].node;
  const timelineimage2 = data.timelineimage2.edges[0].node;
  const timelineimage3 = data.timelineimage3.edges[0].node;
  const timelineimage4 = data.timelineimage4.edges[0].node;
  const timelineimage5 = data.timelineimage5.edges[0].node;
  const timelineimage6 = data.timelineimage6.edges[0].node;
  const timelinetext1 = data.timelinetext1.edges[0].node;
  const timelinetext2 = data.timelinetext2.edges[0].node;
  const timelinetext3 = data.timelinetext3.edges[0].node;
  const timelinetext4 = data.timelinetext4.edges[0].node;
  const timelinetext5 = data.timelinetext5.edges[0].node;
  const timelinetext6 = data.timelinetext6.edges[0].node;

  // Extract section nodes from data
  // const firstSectionNode = data.section1.edges[0].node;
  // const secondSectionNode = data.section2.edges[0].node;
  // const thirdSectionNode = data.section3.edges[0].node;
  // const fourthSectionNode = data.section4.edges[0].node;
  // const fifthSectionNode = data.section5.edges[0].node;
  // const sixthSectionNode = data.section6.edges[0].node;
  
  return (
    <div className="contentFeatureBlog">
      <Helmet>
        <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/3EMP1aRMHimHfO5MQ7qoZ8/2659df6bfd8cd6ff4b343ec787a02a93/blog-post.png" />
        <title>What is a Fresh Kid?</title>
        <meta name="description" content="The Fresh Kid - Luxury data Engineer and Analyst - and Investors Empowering Luxury Leaders and Investors" />
        <meta name="keywords" content="the fresh kid, luxury data analyst" />
        <h1>How Street Culture made Luxury fashion a Billion Dolllar Company</h1>
        <h2>Empowering Luxury Leaders and Investors</h2>
        <h3>Luxury’s Top Investors and Leaders win by understanding the market and customers</h3>
        <h2>Fresh Kids & Street Culture</h2>
      </Helmet>
      <div className={"textContainerBlog"}>
        <p className={"categoryBlog"}>
          {data.allContentfulHeroImage.edges[0].node.blogCategory}
        </p>
        <p className={"readTimeBlog"}>
          {data.allContentfulHeroImage.edges[0].node.readTime}
        </p>
        <p className={"titleBlog"}>
          {data.allContentfulHeroImage.edges[0].node.blogTitle}
        </p>
        <p className={"authorBlog"}>
          {data.allContentfulHeroImage.edges[0].node.author.firstName}
        </p>
        <p className={"dateBlog"}>
          {data.allContentfulHeroImage.edges[0].node.date}
        </p>
      </div>
      <div className="SocialMediaBlog" data-aos="fade-up">
      {isSocialMediaBlogVisible && (
          <>
            <div
              className="Share"
            >
              Share
            </div>
            <a href="https://www.linkedin.com/shareArticle?mini=true&url=thefreshkid.com/the-fresh-kid/">
              <img
                className="SocialIconBlog"
                alt=""
                key=""
                src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.linkedInIcon.image.file.url}
              />
            </a>

            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthefreshkid.com/the-fresh-kid/" target="_blank">
            <img
                className="SocialIconBlog"
                alt=""
                key=""
                src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.facebookIcon.image.file.url}
              />
            </a>

            <a href="mailto:?subject=From thefreshkid.com: What is a Fresh Kid? How Street Culture made Luxury Fashion a Billion Dollar Industry&body=Check out this article: What is a Fresh Kid? How Street Culture made Luxury Fashion a Billion Dollar Industry www.thefreshkid.com/the-fresh-kid/" target="_blank">
              <img
                className="SocialIconBlog1"
                alt=""
                key=""
                src={email}
                style={{ marginRight: '100px' }} 
              />
            </a>
          </>
        )}
      </div>

      {/* https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_sticky_social_bar */}

      <div className={"imageContainerBlog"}>
        <img
          className={"bigImage"}
          alt="The Fresh Kid"
          key={""}
          src={
            data.allContentfulHeroImage.edges[0].node.heroImage.image.file.url
          }
        />
      </div>

      <div className={"blogBodyTextContainer"}>
        <div className={"bodyTextText"}>
          {documentToReactComponents(
            JSON.parse(text1Node.bodyText.raw),
            options
          )}
        </div>
        <div className={"titleText"}>
          <strong>{text2Node.title}</strong>
        </div>
        <div className={"bodyTextText"}>
          {documentToReactComponents(
            JSON.parse(text2Node.bodyText.raw),
            options
          )}
        </div>
        <div className={"titleText"}>
          <strong>{text3Node.title}</strong>
        </div>
        <div className={"bodyTextText"}>
          {documentToReactComponents(
            JSON.parse(text3Node.bodyText.raw),
            options
          )}
        </div>
      </div>

      <h1 className="FK_logo_text">
      <img src={FK} alt="FK" className="FK_logo_pink" />
      </h1>





      <Helmet>
        <h2>How Fresh Kids & Street Culture made Luxury Fashion a Billion dollar Industry</h2>
      </Helmet>
      <div className={"subTitleText"}>
          <strong>{subtitle1.title}</strong>
      </div>

      
      {isContentsVisible && (
        <div>
          <div className="contentsList">
            <p className="content">Contents</p>
            {sectionIds.map((sectionId) => (
              <a
                key={sectionId}
                href={`#${sectionId}`}
                className={activeSection === sectionId ? "active" : ""}
              >
                {sectionId}
              </a>
            ))}
          </div>
        </div>
      )}




      <Helmet>
        <h3>LVMH hires Marc Jacobs as Creative Director of Louis Vuitton</h3>
      </Helmet>
      <div id="1997" className="contentContainer1">
        <p className="yearBlogMediaQuery1">{timelinetext1.year}</p>
        <p className="titleBlogMediaQuery1">{timelinetext1.title}</p>
        <div className="imageContainerBlog1">
          <img
            className="bigImage1"
            alt={timelineimage1.image.altText}
            key={timelineimage1.id}
            src={timelineimage1.image.image.file.url}
          />
        </div>
        <div className="blogBodyTextContainer1">
          <p className="yearBlog1">{timelinetext1.year}</p>
          <p className="titleBlog1">{timelinetext1.title}</p>
          <div className="bodyTextBlog1">
            {documentToReactComponents(
              JSON.parse(timelinetext1.bodyText.raw),
              options
            )}
          </div>
        </div>
      </div>




      <Helmet>
        <h3>Marc Jacobs transforms Louis Vuitton into a billion dollar company</h3>
      </Helmet>
      <div id="2004" className="contentContainer2">
        <p className="yearBlogMediaQuery2">{timelinetext2.year}</p>
        <p className="titleBlogMediaQuery2">{timelinetext2.title}</p>
        <div className="imageContainerBlog2">
          <img
            className="bigImage2"
            alt={timelineimage2.image.altText}
            key={timelineimage2.id}
            src={timelineimage2.image.image.file.url}
          />
        </div>
        <div className="blogBodyTextContainer2">
          <p className="yearBlog2">{timelinetext2.year}</p>
          <p className="titleBlog2">{timelinetext2.title}</p>
          <div className="bodyTextBlog2">
            {documentToReactComponents(JSON.parse(timelinetext2.bodyText.raw), options)}
          </div>
        </div>
      </div>





      <Helmet>
        <h3>Louis Vuitton collaborates with Streetwear brand Supreme</h3>
      </Helmet>
      <div id="2017" className="contentContainer3">
        <p className="yearBlogMediaQuery3">{timelinetext3.year}</p>
        <p className="titleBlogMediaQuery3">{timelinetext3.title}</p>
        <div className="imageContainerBlog3">
          <img
            className="bigImage3"
            alt={timelineimage3.image.altText}
            key={timelineimage3.id}
            src={timelineimage3.image.image.file.url}
          />
        </div>
        <div className="blogBodyTextContainer3">
          <p className="yearBlog3">{timelinetext3.year}</p>
          <p className="titleBlog3">{timelinetext3.title}</p>
          <div className="bodyTextBlog3">
            {documentToReactComponents(JSON.parse(timelinetext3.bodyText.raw), options)}
          </div>
        </div>
      </div>




      <Helmet>
        <h3>Virgil Abloh is appointed Creative Director of Louis Vuitton</h3>
      </Helmet>
      <div id="2018" className="contentContainer4">
        <p className="yearBlogMediaQuery4">{timelinetext4.year}</p>
        <p className="titleBlogMediaQuery4">{timelinetext4.title}</p>
        <div className="imageContainerBlog4">
          <img
            className="bigImage4"
            alt={timelineimage4.image.altText}
            key={timelineimage4.id}
            src={timelineimage4.image.image.file.url}
          />
        </div>
        <div className="blogBodyTextContainer4">
          <p className="yearBlog4">{timelinetext4.year}</p>
          <p className="titleBlog4">{timelinetext4.title}</p>
          <div className="bodyTextBlog4">
            {documentToReactComponents(JSON.parse(timelinetext4.bodyText.raw), options)}
          </div>
        </div>
      </div>


      <Helmet>
        <h3>LVMH posts highest profit and revenue figures in the history of Luxury</h3>
      </Helmet>
      <div id="2022" className="contentContainer5">
        <p className="yearBlogMediaQuery5">{timelinetext5.year}</p>
        <p className="titleBlogMediaQuery5">{timelinetext5.title}</p>
        <div className="imageContainerBlog5">
          <img
            className="bigImage5"
            alt="LVMH "
            key={timelineimage5.id}
            src={timelineimage5.image.image.file.url}
          />
        </div>
        <div className="blogBodyTextContainer5">
          <p className="yearBlog5">{timelinetext5.year}</p>
          <p className="titleBlog5">{timelinetext5.title}</p>
          <div className="bodyTextBlog5">
            {documentToReactComponents(JSON.parse(timelinetext5.bodyText.raw), options)}
          </div>
        </div>
      </div>




      <Helmet>
        <h3>Pharrell Williams appointed Creative Director of Louis Vuitton</h3>
      </Helmet>
      <div id="2023" className="contentContainer6">
        <p className="yearBlogMediaQuery6">{timelinetext6.year}</p>
        <p className="titleBlogMediaQuery6">{timelinetext6.title}</p>
        <div className="imageContainerBlog6">
          <img
            className="bigImage6"
            alt={timelineimage6.image.altText}
            key={timelineimage6.id}
            src={timelineimage6.image.image.file.url}
          />
        </div>
        <div className="blogBodyTextContainer6">
          <p className="yearBlog6">{timelinetext6.year}</p>
          <p className="titleBlog6">{timelinetext6.title}</p>
          <div className="bodyTextBlog6">
            {documentToReactComponents(JSON.parse(timelinetext6.bodyText.raw), options)}
          </div>
        </div>
      </div>

      
    </div>
  );
}
