import * as React from "react"
import LayoutBlog from "../components/LayoutBlog"
import BlogHeader from "../components/BlogPage/BlogHeader"
import CommentBox from '../components/BlogPage/CommentBox'

function blog({ data }) {
  return (
    <LayoutBlog>
      <BlogHeader/>
      <CommentBox />
    </LayoutBlog>
  )
}

export default blog